import { graphql } from "gatsby";
import React from "react";
import Layout from "~components/layouts/Layout";
import FeatureList from "~components/sections/landingpages/FeatureList";
import KeySellingpoints from "~components/sections/landingpages/KeySellingpoints";
import LandingpageHeader from "~components/sections/landingpages/LandingpageHeader";
import SeoText from "~components/sections/landingpages/SeoText";
import Cta from "~components/sections/Cta";
import About from "~components/sections/landingpages/About";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";

export default function Page({ data, location }) {
	const keyword = "Webdesign für Steuerberater";
	const title = "Webdesign für Steuerberate zur Mandanten-Generierung";
	const description =
		"Mit Webdesign für Steuerberater entwickeln wir mandantenorientierte Website, mit denen Du an Sichtbarkeit und Zeit gewinnst.";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.headerImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.headerImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.headerImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<SchemaProduct
				name={keyword}
				image={data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}
				description={description}
				aggregateOffer
				lowPrice="500"
				highPrice="5000"
				url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
			/>
			<LandingpageHeader
				headline={{ one: "Webdesign für Steuerberater:", two: "Websites mit Blick auf Mandanten-Generierung." }}
				image={{ data: data.headerImage.header, alt: `${keyword} - Steuerberater im Portrait` }}
				text={
					<>
						Mit <strong>Webdesign für Steuerberater</strong> entwickeln wir mandantenorientierte Website, mit denen Die
						Du Kunden und Zeit gewinnen kannst. Zudem entwickeln wir kommunikativ Systeme und bauen Dir so eine Website,
						die auch in Zukunft alle Erwartungen übertrifft.
					</>
				}
				buttonOne={{ text: "Kostenloses Beratungsgespräch vereinbaren", to: `${location.pathname}anfragen/` }}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
			/>

			<KeySellingpoints
				sellingponts={[
					{
						headline: "Das Image der Kanzlei verbessern",
						text: [
							"Eine Website für Dich als Steuerberater hat viele Vorteile, die Dir vielleicht auf den ersten Blick nicht bewusst sind. Du möchtest einen ordentlichen und zuverlässigen Eindruck bei Deinen Mandanten hinterlassen? Dann ist eine professionelle Website in der heutigen Zeit ein Muss, da sie wesentlich für das Image der Kanzlei beiträgt. Eine lieblose Website mit falschen Informationen, welche nur bedingt bedienbar ist, führt dazu, dass Mandanten einen ersten schlechten Eindruck von Dir erhalten. Die Website dient als digitale Eintrittstür zu Deiner Kanzlei, egal ob Du willst oder nicht. Solltest Du keine Website haben, verliert sich der Patient im World Wide Web und landet eventuell auf Seiten, auf denen falsche oder veraltete Informationen über Dich hinterlegt sind. Um dies zu vermeiden, kann eine eigene Website helfen.",
						],
						image: data.imageEmfpang,
						alt: `${keyword} - Mitarbeiterin mit Mandanten am Empfang`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Image verbessern",
						},
					},
					{
						headline: "Zufriedenheit der Mandanten und Kommunikation",
						text: [
							"Die Mandantenbeziehungen sind oftmals eine seriöse und vertrauensvolle Sache. Der Mandant legt seine Finanzen in die Verantwortung Deiner Hände. Es ist also notwendig, dass Kompetenz und Zuverlässigkeit des Informationsflusses vom Empfang bis hin zum Finanzamt stattfinden kann. Allerdings beginnt die Informationsaufnahme des Mandanten nicht bei Dir in der Kanzlei, sondern im Netz. Die Anfahrt, Sprechzeiten und Kernkompetenzen der Steuerkanzlei müssen schon auf der Website vermittelt werden. Zudem kann eine ordentlich geführte Website den Empfang entlasten und Du sparst zudem Verwaltungsaufwand und kannst Dich mehr auf Deine Mandanten konzentrieren.",
						],
						image: data.imageZufrieden,
						alt: `${keyword} - Zufriedener Mandant`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Beziehung verbessern",
						},
					},
					{
						headline: "Sichtbarkeit und Kundenakquise",
						text: [
							"Die Website eines Steuerberaters dient hauptsächlich zur Akquise von Mandanten. Durch intelligente Suchmaschinenoptimierung befördern wir Deine Website in die obersten Ränge der Suchmaschine. Dadurch erschaffen wir einen ständigen und automatischen Kundenstrom, ohne dauerhaften Werbekosten. Diese Art des Marketings kennzeichnet sich durch Nachhaltigkeit und hält bei einer einmaligen Investition meist mehrere Jahre.",
						],
						image: data.imageSuche,
						alt: `${keyword} - Kunde sucht online einen Steuerberater`,
						button: {
							to: `${location.pathname}anfragen/`,
							text: "Kundenakquise fördern",
						},
					},
				]}
			/>

			<FeatureList
				headline={`${keyword}: Unser Leistungsspektrum`}
				text={`Uns sind ganzheitliche Lösungen für unsere Kunden über aus wichtig. Daher bieten wir auch für den Bereich ${keyword} eine ganzheitliche Lösung für Deine Website. Ein einfacher Auftrag genügt und wir kümmern uns um die erfolgreiche Projektumsetzung und darüber hinaus.`}
				features={data.site.siteMetadata.globalFeautureList.webdesign}
			/>

			<SeoText
				headline="Was ist gutes Webdesign für Steuerberater?"
				intro={[
					"Für Dich als Steuerberater haben wir uns bestimmte Lösungen ausgedacht, die besonders wertvoll für Dich sein könnten. Daher koppeln wir unser Dienstleistungsspektrum mit folgenden Leistungspunkten:",
				]}
				text={[
					{
						headline: "Modern, inhaltsstark und kundenorientiert",
						text: [
							"Eine gute Homepage glänzt nicht nur durch gutes Design. Es stimmt, dass eine Homepage modern sein soll und den ersten WOW-Effekt auslösen sollte. Doch der WOW-Effekt ist nur für den ersten Eindruck dienlich. Neben dem Design sind die Inhalte das Kernstück jeder Website. Keine Website überzeugt ausschließlich durch ein schönes Auftreten. Der Besucher einer Website hat immer ein Bedürfnis, welches gestillt werden muss. Bei einer Steuerberaterhomepage hat der Patient vorwiegend das Bedürfnis nach einer Lösung zu seinen steuerlichen Herausforderungen. Sollte der Mandant Dich nicht kennen, ist eine Auflistung der Kernkompetenzen des Steuerberaters wichtig. Sollte der richtige Steuerberater gefunden worden sein, ist eine schnelle und einfache Terminfindung für beide Seiten sinnvoll. Natürlich sind die Sprechzeiten und die Adresse unabdingbar auf der Website, aber wäre es nicht sinnvoll auch eine automatische Terminfindung in die Homepage einzubauen? So entlastest Du Deinen Empfang und der Mandant kann sich direkt einen freien Termin auf der Homepage aussuchen. Zudem hast Du den Vorteil, dass Du der Name, Telefon und E-Mail schwarz auf weiß vorliegen hast. Eine gute Website ist also mandantenorientiert, hilft dabei, den richtigen Steuerberater zu finden und macht die Terminbuchung spielend einfach.",
						],
					},
					{
						headline: "News",
						text: [
							"Die Coronapandemie hat gezeigt, wie wichtig aktuelle Nachrichten für Mandanten sein müssen. Als Steuerberaterkanzlei ist es umso wichtiger, wie Du als Kanzlei auf bestimmte Umstände reagierst und was bei Dir konkret ansteht. Damit Deine Patienten bezüglich Deiner Kanzlei immer auf dem neusten Stand sind, ist ein News-System sinnvoll. So kannst Du die Homepage als Sprachrohr zu Deinen Kunden nutzen und informierst ihn bei besonderen Umständen. Zudem kannst Du immer auf die Website verweisen, sollten sich Fragen häufen, die den Betriebsfluss innerhalb der Kanzlei stören.",
						],
					},
					{
						headline: "Userexperience und Barrierefreiheit",
						text: [
							"Die Website ist, wenn Du alle vorangegangen Punkte beachtest, modern, sichtbar und nützlich. Entscheidend ist nun, dass auch der Mandant eine wundervolle Erfahrung auf der Website hat. Alle Website, die länger als zwei Sekunden laden, gelten als sehr langsam. Das ist wichtig, da der Website-Besucher schnell wieder von Websites abspringt, sollten diese nicht rechtzeitig laden. Die Website muss also rasant laden können.",
						],
					},
					{
						headline: "Userinterface",
						text: [
							"Eine Website im 21. Jahrhundert ist auf allen erdenklichen Endgeräten erreichbar. Webdesigner nennen diesen Ansatz Responsive Webdesign. Dieser Ansatz ermöglicht es, dass eine Website dynamisch wie ein Gummiband auseinandergezogen werden kann, sodass sich das Userinterface der Breite des Monitors oder Fensters anpasst. Zudem ist es wichtig, dass auch alle Elemente klar und deutlich hervorgehoben sind und leicht bedienbar sind. Ein kleiner Button der präzise geklickt werden muss ist frustrierend und dient als schlechtes Beispiel für Webdesign. Achte darauf, dass jedes gestaltete Userinterface nicht nur wunderschön ist, sondern auch sinnvoll gestaltet ist und alles bedienbar ist. Der Besucher der Website muss sich, ohne lange zu suchen, schnell auf der Website zurechtfinden können. Eine sinnvolle und verständnisvolle Struktur der Website hilft enorm.",
						],
					},
				]}
			/>

			<Cta
				title={`${keyword} jetzt anfragen`}
				titleAs="p"
				text="Haben wir Dein Interesse geweckt? Dann fragen gerne ein unverbindliches Gespräch bei uns an und wir schauen, ob wir zueinanderpassen."
				button={`${keyword} anfragen`}
				to={`${location.pathname}anfragen/`}
			/>

			<SeoText
				headline="Worauf achten wir beim Webdesign für Steuerberater?"
				intro={[
					"Viele der vorangegangenen Punkte sind essenziell für ein gutes Webdesign für Steuerberater. Doch was macht Lautenschlager Marketing & Entwicklung noch mal anders oder besser als die Konkurrenz? Wir möchten ganzheitliche Lösungen anbieten, ohne langes hin und her. Du sollst Dich auf Deinen Dienstleister verlassen können und Dich auf Deine Mandanten konzentrieren, daher gehen wir einen Schritt weiter und bieten neben den voran gegangenen Punkte zu jeder Website folgende Dinge an.",
				]}
				text={[
					{
						headline: "Wahlweise passendes modernes Logo",
						text: [
							"Ein Logo ist das Gesicht nach Draußen zu Deinen Kunden. Jede Kanzlei, die sich professionell aufstellen will, benötigt ein ordentliches und einmaliges Logo, das einen Wiedererkennungswert bietet. Das Logo ist auch notwendig für jede Website, wie sonst soll der Kunde wissen, dass er auf der richtigen Website ist? Solltest Du über kein Logo besitzen, können wir Dir ein Logo, sowie jegliche Geschäftsausstattung entwickeln lassen.",
						],
					},
					{
						headline: "Wahlweise schöne Fotos Deiner Kanzlei",
						text: [
							"Du hast keine guten Fotos von Deiner Kanzlei? Eine Website lebt von den Bildern aus der Kanzlei – egal ob es um Raum- oder Porträtfotos geht. Gerade als professionelle Kanzlei, die nahbar für seine Kunden sein will, benötigt echte Fotos aus der Kanzlei. So fühlt sich der Besucher der Website direkt heimisch.",
						],
					},
					{
						headline: "Verständnisvolle und einfache Texte",
						text: [
							"Die Kommunikation zu den Mandanten kann oft zu Missverständnissen führen. Um möglichst wenig Reibungsfläche zu den Kunden zu entwickeln, verzichten wir auf komplizierte Texte. Unsere einfachen, aber dennoch fachlich korrekten Texte ermöglichen einen verständnisvollen Duktus.",
						],
					},
					{
						headline: "Website-Pflege",
						text: [
							"Damit die Website nicht in Vergessenheit gerät oder aufgrund von Zeitmangel nicht aktualisiert wird, bieten wir neben normalen Webdesign auch unser Website-Abo an. Mit dieser Dienstleistung möchten wir allen Steuerberatern helfen, die keine Zeit oder Muse haben, sich um die eigene Website zu kümmern. Mit dem Website-Abo buchen unsere Steuerberater eine Dienstleistung mit Rückenwind. Für einen festen Betrag stehen wir immer zur Verfügung und ändern jegliche Inhalte auf der Website, sollte etwas anstehen. Eine einfache kurze E-Mail an uns reicht und wir machen uns an die Arbeit, die Website zu aktualisieren, ohne lästige Stundenabrechnungen.",
						],
					},
					{
						headline: "Verschlüsselte Daten in Deutschland",
						text: [
							"Kunden müssen Dir vertrauen und daher werden alle Daten, die über die Website verarbeitet werden, über eine verschlüsselte Datenverbindung übertragen. Zudem nutzen wir keine ausländischen Datenspeicherorte. Alle Kunden, die bei uns hosten, erhalten einen zuverlässigen und DSGVO-konformen Dienstleister, der Server in Deutschland ansiedelt. So vermeiden wir Datenmissbrauch.",
						],
					},
				]}
			/>
			<About link={`${location.pathname}anfragen/`} keyword={keyword} />
		</Layout>
	);
}

export const query = graphql`
	{
		headerImage: file(
			relativePath: { eq: "pages/leistungen/webdesign-steuerberater/webdesign-steuerberater_header.jpg" }
		) {
			header: childImageSharp {
				gatsbyImageData
			}
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageEmfpang: file(
			relativePath: { eq: "pages/leistungen/webdesign-steuerberater/webdesign-steuerberater_image.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageZufrieden: file(
			relativePath: { eq: "pages/leistungen/webdesign-steuerberater/webdesign-steuerberater_zufriedenheit.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageSuche: file(
			relativePath: { eq: "pages/leistungen/webdesign-steuerberater/webdesign-steuerberater_suche.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		site {
			siteMetadata {
				siteUrl
				globalFeautureList {
					webdesign {
						headline
						text
					}
				}
			}
		}
	}
`;
